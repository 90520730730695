import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { LocationAddress } from "src/app/locations/models/location-address";
import { TimeService } from "../../time/services/time.service";

interface ConfirmationConfiguration {
    sourceField: string;
    confirmationField: string;
    errorName: string;
    errorMessage: string;
}

interface TimeCheckingConfiguration {
    startTimeField: string;
    endTimeField: string;
    errorName: string;
    errorMessage: string;
}

@Injectable({
    providedIn: 'root'
})
export class FormValidationService {

    constructor(
        private timeService: TimeService
    ) { }

    public fieldsMatch(config: ConfirmationConfiguration): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control) {
                return null;
            }

            const confirmationError = { [`${config.errorName}`]: config.errorMessage };

            const confirmationFieldControl = control.get(config.confirmationField);

            if (!confirmationFieldControl) {
                throw new Error('No confirmation field control was set for the form');
            }

            let sourceValue = control.get(config.sourceField)!.value;
            let confirmationValue = confirmationFieldControl.value;

            const hasMismatch = sourceValue !== confirmationValue;

            if (hasMismatch) {
                const errors = { ...confirmationError, ...confirmationFieldControl.errors };
                confirmationFieldControl.setErrors(errors);
            }

            else {
                if (confirmationFieldControl.hasError(config.errorName)) {
                    delete confirmationFieldControl.errors![config.errorName];

                    if (Object.keys(confirmationFieldControl.errors!).length === 0) {
                        confirmationFieldControl.setErrors(null);
                    }
                }
            }

            return hasMismatch ? confirmationError : null;
        }
    }

    public endTimeStartsAfterStartTime(config: TimeCheckingConfiguration): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control) {
                return null;
            }

            const timeMismatchError = { [`${config.errorName}`]: config.errorMessage };

            const startTimeFieldControl = control.get(config.startTimeField);
            const endTimeFieldControl = control.get(config.endTimeField);

            if (!startTimeFieldControl) {
                throw new Error('No start time field control was set for the form');
            }

            if (!endTimeFieldControl) {
                throw new Error('No end time field control was set for the form');
            }

            let startTimeValue = this.timeService.convertDateStringToEpochMilliseconds(startTimeFieldControl.value);
            let endTimeValue = this.timeService.convertDateStringToEpochMilliseconds(endTimeFieldControl.value);

            const timeMismatch = startTimeValue > endTimeValue;

            if (timeMismatch) {
                const errors = { ...timeMismatchError, ...endTimeFieldControl.errors };
                endTimeFieldControl.setErrors(errors);
            }

            return timeMismatch ? timeMismatchError : null;
        };
    }

    public getMillisecondsFromTimeString(value: string): number {
        const date = new Date(value);

        const milliseconds = date.getTime();
        return milliseconds;
    }

    public minimumLength(minLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const errorName = 'minLength';

            if (minLength <= 0) {
                throw new Error('Minimum length must be greater than 0.');
            }

            if (!control) {
                return null;
            }

            const error = { [`${errorName}`]: `Length must be at least ${minLength}` };

            const value = control!.value;

            const hasNotMetMinLength = value?.length < minLength;

            return hasNotMetMinLength ? error : null;
        }
    }

    public requiredField(fieldName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control) {
                return null;
            }

            if (!control.value) {
                return {
                    required: `${fieldName} is required.`
                }
            }

            return null;
        }
    }

    public requireValidPhoneNumber(): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => { 
            if (!control || !control.value) {
                return null;
            }

            const errors: any = {};

            const phoneNumber = control.value;

            const isValid = RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/).test(phoneNumber);

            if (!isValid) {
                errors['invalidPhoneNumber'] = 'Phone number is invalid.';
            }

            return Object.keys(errors).length > 0 ? errors : null;
        }
    }

    public requireValidEmailAddress(): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            if (!control || !control.value) {
                return null;
            }

            const errors: any = {};

            const email = control.value;

            const isValid = RegExp(/^([^@]*)@{1}([^@]*)\.+([^@]*)$/).test(email);

            if (!isValid) {
                errors['invalidEmail'] = 'Email address is invalid.';
            }

            return Object.keys(errors).length > 0 ? errors : null;
        }
    }

    public requireValidAddress(): ValidatorFn {
        return (control: AbstractControl<LocationAddress>): ValidationErrors | null => {
            if (!control || !control.value) {
                return null;
            }

            const errors: any = {};

            const address = control.value;

            if (!address.addressOne) {
                errors['missingStreet'] = 'Must have a street address.';
            }

            if (!address.city) {
                errors['missingCity'] = 'Must have a city.';
            }

            if (!address.state) {
                errors['missingState'] = 'Must have a state.';
            }

            if (!address.zip) {
                errors['missingZip'] = 'Must have a valid zip code.';

            }

            return Object.keys(errors).length > 0 ? errors : null;
        }
    }
}