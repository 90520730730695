<nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
        <div class="d-flex">
            <div role="button" class="me-2 search-icon d-lg-none sticky" data-bs-toggle="offcanvas"
                data-bs-target="#searchDrawer" aria-controls="searchDrawer" (click)="showSearch = !showSearch"
                id="mobile-search-icon">
                <app-icon [icon]="showSearch ? 'x' : 'search'"></app-icon>
            </div>
            <button class="navbar-toggler sticky" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#navbarDrawer" aria-controls="navbarDrawer">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="navbarDrawer" aria-labelledby="navbarDrawerLabel">
            <div class="offcanvas-header">
                <ng-container *ngTemplateOutlet="logo"></ng-container>
                <button type="button" class="btn-close btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
                    id="closeNormalDrawerButton"></button>
            </div>
            <div class="row sticky">
                <div class="col d-none d-lg-block mt-md-2">
                    <app-event-search *ngIf="showSearch"></app-event-search>
                </div>
                <div class="col d-lg-flex justify-content-lg-end justify-content-center pe-2 pt-2 pt-lg-0">
                    <div role="button" class="me-2 search-icon d-none d-lg-block" (click)="showSearch = !showSearch"
                        id="desktop-search-icon">
                        <app-icon [icon]="showSearch ? 'x' : 'search'"></app-icon>
                    </div>
                    <ng-container *ngIf="currentUser">
                        <div class="d-flex d-lg-initial justify-content-center ms-lg-2 my-2 my-lg-0">
                            <button class="btn btn-lg w-75 w-lg-auto" routerLink="/events/create"
                                (click)="closeDrawer()">
                                <div class="d-flex justify-content-center" style="font-size: 14px;">
                                    <div class="me-1">Create Event</div>
                                    <app-icon icon="plus"></app-icon>
                                </div>
                            </button>
                        </div>
                        <div class="d-flex justify-content-center ms-lg-2 my-2 my-lg-0">
                            <button class="btn btn-alt-lg w-75 w-lg-auto" routerLink="/events/manage"
                                (click)="closeDrawer()">
                                <div class="d-flex justify-content-center" style="font-size: 14px;">
                                    <div class="me-1">Manage Events</div>
                                    <app-icon icon="user"></app-icon>
                                </div>
                            </button>
                        </div>

                        <div class="d-flex justify-content-center ms-lg-2 my-2 my-lg-0">
                            <button class="btn btn-lg w-75 w-lg-auto" (click)="logout()"
                                (click)="closeDrawer()">Logout</button>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="!currentUser">
                        <button class="btn w-75 w-lg-auto" routerLink="/login"
                            (click)="closeDrawer()">Login</button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="offcanvas offcanvas-end d-lg-none" tabindex="-1" id="searchDrawer"
            aria-labelledby="searchDrawerLabel">
            <div class="offcanvas-header">
                <ng-container *ngTemplateOutlet="logo"></ng-container>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
                    (click)="showSearch = false" id="closeSearchDrawerButton"></button>
            </div>
            <div class="mt-3 p-2">
                <app-event-search (searchExecuted)="closeSearchDrawer()"></app-event-search>
            </div>
        </div>
    </div>
</nav>
<ng-template #logo>
    <a class="navbar-brand ms-lg-2 sticky p-4" routerLink="/home">
        <img src="assets/images/eventtube-logo.png">
    </a>
</ng-template>